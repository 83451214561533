import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import { getLocaleCopies } from "../../../helpers/locale_management";
import JobCard from "../../jobs/JobCard/JobCard";
import { JobCardSkeletonMobile } from "../../jobs/JobCardSkeleton/JobCardSkeleton";
import {
  DiscoverButton,
  EmptyContainer,
  OuterGrid,
  SectionTitle,
  TrendingJobsGrid,
  Wrapper,
} from "./styles";

function HomepageJob() {
  const Router = useRouter();
  const trendingJobs = useSelector((state) => state.jobs.trendingJobs);
  const fetchingTrendingJobs = useSelector(
    (state) => state.jobs.fetchingTrendingJobs
  );

  const copies = getLocaleCopies();

  const redirect = () => {
    Router.push("/jobs");
  };

  return (
    <EmptyContainer>
      <OuterGrid
        container
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection="column"
      >
        <SectionTitle>{copies.homepageTrendingTitleText}</SectionTitle>

        <Wrapper container justifyContent="center" rowGap="30px">
          {fetchingTrendingJobs ? (
            <TrendingJobsGrid container gap={5} justifyContent="center">
              {Array.from({ length: 6 }).map((index) => {
                return <JobCardSkeletonMobile key={index} />;
              })}
            </TrendingJobsGrid>
          ) : (
            trendingJobs?.slice(0, 6).map((job) => (
              <TrendingJobsGrid
                container
                item
                justifyContent="center"
                xs={12}
                md={5}
                tabletS={6}
                lg={4}
                key={job.id}
              >
                <JobCard homepage={true} job={job} origin="homepage_trending" />
              </TrendingJobsGrid>
            ))
          )}
        </Wrapper>
        <EmptyContainer
          container
          justifyContent="center"
          padding="0 0 50px 0px"
        >
          <DiscoverButton onClick={redirect} solid={true}>
            {copies.homepageTrendingJobButtonText}
          </DiscoverButton>
        </EmptyContainer>
      </OuterGrid>
    </EmptyContainer>
  );
}

export default HomepageJob;
