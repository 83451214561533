import Link from "next/link";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assetDomain } from "../../../config/config";
import { getHomepageBanners } from "../../../redux/actions/job_action";
import { InnerGrid, OuterGrid } from "./styles";

function HomepageBanner() {
  const dispatch = useDispatch();
  const homepageBanners = useSelector((state) => state?.jobs?.homepageBanners);
  const logo = assetDomain + "/images/logo/naikgaji-logo.png";

  useEffect(() => {
    dispatch(getHomepageBanners());
  }, []);

  return (
    <OuterGrid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      style={{
        display: "flex",
        objectFit: "contain",
        backgroundImage: `url("${homepageBanners?.node?.image}")`,
        zIndex: 0,
        order: 0,
      }}
    >
      <Link
        style={{ width: "100%", textDecoration: "none" }}
        href={`${homepageBanners?.node?.urlLink}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <InnerGrid
          container
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          {/* <LinkStyled href={"/companies"}> */}
          {/* <ImageGrid>
            <ImageStyled src={logo} />
          </ImageGrid> */}
          {/* </LinkStyled> */}
        </InnerGrid>
      </Link>
    </OuterGrid>
  );
}

export default HomepageBanner;
