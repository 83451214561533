import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";
import Link from "next/link";
import Color from "../../../assets/colors";

export const OuterGrid = styling(Grid)({
  height: "500px",
  width: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  position: "relative",
});

export const TitleStyled = styling(Typography)((props) => ({
  fontFamily: "Rubik",
  fontSize: "60px",
  fontWeight: 700,
  lineHeight: "70px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "white",

  "&.MuiTypography-root > span": {
    color: Color.naikGreen,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "50px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "28px",
    padding: "0 15%",
  },
}));

export const SubtitleStyled = styling(Typography)((props) => ({
  fontSize: "32px",
  fontWeight: "bold",
  color: "white",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "18px",
    marginBottom: "150px",
  },
}));

export const ImageSubtitleStyled = styling(Typography)((props) => ({
  fontSize: "14px",
}));

export const ImageStyled = styling("img")({
  objectFit: "scale-down",
  maxHeight: "90px",
  maxWidth: "150px",
});

export const ImageGrid = styling(Grid)((props) => ({
  position: "absolute",
  bottom: "15px",
  right: "25px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px 8px",

  [props.theme.breakpoints.down("tablet")]: {
    position: "relative",
    bottom: 0,
    right: 0,
  },
}));

export const LinkStyled = styling(Link)((props) => ({
  //
}));

export const InnerGrid = styling(Grid)({
  height: "500px",
  width: "100%",
  position: "relative",
  opacity: 1,
  //background: `linear-gradient(180deg, rgba(34, 34, 34, 0) 4.69%, #222222 88.26%)`,
});
