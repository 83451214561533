import { Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const OuterGrid = styling(Grid)({
  minHeight: "80px",
  backgroundColor: Color.naikGreen,
});

export const InnerGrid = styling(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
});

export const SearchText = styling(Typography)((props) => ({
  color: Color.white,
  fontSize: "25px",
  lineHeight: "32px",

  [props.theme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
  [props.theme.breakpoints.down("tablet")]: {
    width: "100%",
    fontSize: "18px",
  },
}));

export const TextFieldContainer = styling(Grid)((props) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "baseline",
  maxWidth: "240px",

  [props.theme.breakpoints.down("tablet")]: {
    maxWidth: "280px",
    display: "flex",
    justifyContent: "center",
  },
}));

export const TextFieldStyled = styling(TextField)((props) => ({
  color: "#ffffff",
  marginLeft: "12px",
  width: "200px",

  input: {
    textAlign: "center",
    color: Color.white,
    fontSize: "25px",
  },

  label: {
    color: Color.white,
    fontSize: "25px",
  },

  "& ::placeholder": {
    color: Color.white,
    opacity: 1,
  },

  "& .MuiInputBase-input:focus::placeholder": {
    color: "transparent",
  },

  "  & .MuiInputBase-input": {
    borderColor: "#FFFFFF !important",
  },

  "& .MuiInput-underline::before": {
    borderColor: "#FFFFFF !important",
  },

  "& .MuiInput-underline:hover:before": {
    borderColor: "#FFFFFF !important",
  },

  "& .MuiInput-underline::after": {
    borderColor: "#FFFFFF !important",
  },

  [props.theme.breakpoints.down("lg")]: {
    input: {
      fontSize: "25px",
    },

    label: {
      fontSize: "25px",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    input: {
      fontSize: "18px",
    },

    label: {
      fontSize: "18px",
    },
  },
}));

export const ButtonGrid = styling(Grid)({
  padding: "16px 8px",
  marginLeft: "10px",
});

export const DiscoverButton = styling(Button)((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 8px",
  gap: "8px",
  minWidth: "150px",
  minHeight: "42px",
  border: `1px solid ${Color.white}`,
  borderRadius: "6px",
  boxShadow: "none",
  color: Color.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: "700",

  "&:hover": {
    border: "none",
    backgroundColor: Color.taglineGreen,
  },

  "&:focus": {
    border: "none",
    backgroundColor: Color.taglineGreen,
  },
}));
