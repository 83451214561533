import { Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const OuterGrid = styling(Grid)({
  height: "100%",
  width: "100%",
  margin: "auto",
  maxWidth: "1300px",
  padding: "50px",
  backgroundColor: Color.backgroundGrey,
});

export const SectionTitle = styling(Typography)((props) => ({
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  marginBottom: "70px",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "28px",
    marginBottom: "50px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "20px",
    marginBottom: "30px",
  },
}));

export const DiscoverButton = styling(Button)((props) => ({
  width: "180px",
  height: "48px",
  borderRadius: "6px",
  textTransform: "none",
  backgroundColor: "black",
  border: "0.5px solid black",
  color: "white",
  fontWeight: "bold",

  ":disabled": {
    color: "white",
    backgroundColor: Color.grey,
  },

  ":hover": {
    backgroundColor: props.solid ? "#000000" : "white",
    boxShadow: `4px 4px 6px rgba(0, 0, 0, 0.2)`,
    border: props.solid ? "0.5px solid black" : "0.5px solid black",
  },
}));

export const DividerLine = styling(Divider)((props) => ({
  width: "75px",
  borderTop: `2px solid ${Color.naikGreen}`,
  marginBottom: "20px",
}));

export const TrendingJobsGrid = styling(Grid)((props) => ({}));

export const Wrapper = styling(Grid)((props) => ({
  marginBottom: "50px",
}));

export const EmptyContainer = styling(Grid)((props) => ({
  backgroundColor: Color.backgroundGrey,
}));
