import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { localisation } from "../../../helpers/constant";
import en from "../../../locales/en";
import ms from "../../../locales/ms";
import { hideSnackBar } from "../../../redux/actions/snackbar_action";
import {
  CloseIconStyled,
  SeverityContainer,
  SnackBarContainer,
  SnackbarAccent,
  SnackbarCheckCircleIconStyled,
  SnackbarErrorCircleIconStyled,
  SnackbarIconButtonStyled,
  SnackbarMessageText,
  SnackbarSeverityText,
  SnackbarStyled,
} from "./styles";

function SharedSnackbar(props) {
  const dispatch = useDispatch();

  // selectors
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackBarMessage
  );
  const severity = useSelector((state) => state.snackbar.severity);
  const showSnackBar = useSelector((state) => state.snackbar.showSnackBar);
  const snackBarKey = useSelector((state) => state.snackbar.key);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleSnackBarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackBar());
  };

  const router = useRouter();

  const { locale } = router;

  const copies =
    locale === localisation.ENGLISH
      ? en
      : locale === localisation.BAHASA_MALAYSIA
      ? ms
      : ms;

  return (
    <SnackbarStyled
      key={snackBarKey}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={showSnackBar}
      autoHideDuration={6000}
      onClose={handleSnackBarClosed}
    >
      <SnackBarContainer $fullWidth={isMobile ? true : false}>
        <SnackbarAccent $severity={severity}></SnackbarAccent>
        <SeverityContainer>
          <SeverityContainer>
            {severity === "success" || severity === "ashley" ? (
              <SnackbarCheckCircleIconStyled $severity={severity} />
            ) : (
              <SnackbarErrorCircleIconStyled />
            )}

            <SnackbarSeverityText variant="subtitle1">
              {severity === "success"
                ? copies.success
                : severity === "ashley"
                ? "Ashley sleeps"
                : copies.error}
            </SnackbarSeverityText>
          </SeverityContainer>
          <SnackbarIconButtonStyled onClick={handleSnackBarClosed}>
            <CloseIconStyled />
          </SnackbarIconButtonStyled>
        </SeverityContainer>
        <SnackbarMessageText variant="subtitle2">
          {snackbarMessage}
        </SnackbarMessageText>
      </SnackBarContainer>
    </SnackbarStyled>
  );
}

export default SharedSnackbar;
