import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocaleCopies } from "../../../helpers/locale_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updateJobsParams } from "../../../redux/actions/job_action";
import {
  ButtonGrid,
  DiscoverButton,
  InnerGrid,
  OuterGrid,
  SearchText,
  TextFieldContainer,
  TextFieldStyled,
} from "./styles";

const companyNames = [
  "Shopline Malaysia",
  "Lazada Malaysia",
  "VF Corporation",
  "Ninja Van",
  "AIA BHD",
  "Hiredly.com",
];

function HomepageSearch() {
  const router = useRouter();

  const { locale } = router;

  const copies = getLocaleCopies();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [currentSearchVal, setCurrentSearchVal] = useState("");

  const dispatch = useDispatch();

  let searchParams = useSelector((state) => state.jobs?.jobsParams);

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % companyNames.length);
    }, 1000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [currentIndex, companyNames.length]);

  const handleChange = (event) => {
    clearInterval(intervalId);
    setIntervalId(null);

    setCurrentSearchVal(event.target.value ?? "");
    searchHandler(event);
  };

  const searchHandler = (e) => {
    searchParams = {
      ...searchParams,
      keyword: e.target.value,
      keywordDisplay: e.target.value,
    };

    if (searchParams?.keyword?.length === 0) {
      searchParams = {
        ...searchParams,
        keyword: "*",
      };
    }
    dispatch(updateJobsParams(searchParams));
  };

  const handleRouteToJobs = (event) => {
    if (event.key === "Enter") {
      router.push("/jobs");
    }
  };

  const getTextFieldVal = () => {
    if (intervalId) return companyNames[currentIndex];

    return currentSearchVal ?? "";
  };

  const handleSearch = () => {
    sendTrackingEvent({
      event: "CE_search-job",
      "search-term": currentSearchVal,
    });
    router.push("/jobs");
  };

  return (
    <OuterGrid
      container
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <InnerGrid>
        <SearchText>{copies.homepageSearchText}</SearchText>
        <TextFieldContainer>
          <TextFieldStyled
            onKeyDown={handleRouteToJobs}
            variant="standard"
            id="standard"
            placeholder={"Company Name"}
            autoComplete="none"
            value={getTextFieldVal()}
            onChange={handleChange}
            onClick={handleChange}
          />
        </TextFieldContainer>
        <ButtonGrid>
          <DiscoverButton variant="outlined" onClick={handleSearch}>
            {copies.homepageSearchButtonText}
          </DiscoverButton>
        </ButtonGrid>
      </InnerGrid>
    </OuterGrid>
  );
}

export default HomepageSearch;
