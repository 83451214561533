import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedSnackbar from "../../../components/shared/SharedSnackbar/SharedSnackbar";
import { getTrendingJobs } from "../../../redux/actions/job_action";
import AppBar from "../../shared/SharedNavbar/SharedNavbar";
import HomepageBanner from "../HomepageBanner/HomepageBanner";
import HomepageJob from "../HomepageJob/HomepageJob";
import HomepageSearch from "../HomepageSearch/HomepageSearch";

const HomepageWrapper = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrendingJobs());
  }, []);

  return (
    <Fragment>
      <AppBar />
      <HomepageBanner />
      <HomepageSearch />
      <HomepageJob />
      <SharedSnackbar />
    </Fragment>
  );
};

export default HomepageWrapper;
